
    .CardsItemWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 290px;
      height: 400px;
      border: 1px solid #E5E5E5;
      border-radius: 5px;
      margin: 10px;
    }

    .CardsItemTop {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      width: 100%;
      padding: 10px 10px;
      box-sizing: border-box;
    }

    .CardsItemBottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 50%;
      width: 100%;
      padding: 0px 20px;
      box-sizing: border-box;
      text-align: center;
    }

    .txtCardsName {
      font-weight: 400;
      margin-bottom: 10px;
      font-size: 24px;
      color: #333;
    }

    .txtCardsAbout {
      margin-top: 5px;
      font-weight: 300;
    }

    .txtCardsPrice {
      color: #666;
      font-weight: bold;
      line-height: 1.8;
      margin: 8px 0 0 0;
    }

    .wrapperCardsButton {
      display: flex;
      width: 150px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      cursor: pointer;
      box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1);
      outline: none;
      border: none;
      background: linear-gradient(0deg, #ea402f 0%, #ea402f 100%);
      color: #fff;
      overflow: hidden;    
      margin-top: 30px;
    }
 
    .txtCardsButton {
      font-size: 15px;
      font-weight: 600;
      font-family: system-ui;
      color: white;
    }

    .wrapperCardsButton:hover {
      opacity: .7;
    }

    .wrapperCardsButton:active{
      box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
                  -4px -4px 6px 0 rgba(116, 125, 136, .2), 
        inset -4px -4px 6px 0 rgba(255,255,255,.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
    }
