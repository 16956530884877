.footer_container {
  display:flex;flex-direction:column;width:100%;background-color:#333;align-items:center;justify-content:center;
}
.footer_up {
  display:flex;flex-direction:row;width:100%;background-color:#363636;align-items:flex-start;justify-content:space-evenly;padding-top:50px;z-index:0
}
  .footer_down {
    display:flex;flex-direction:column;width:100%;background-color:#363636;align-items:center;justify-content:center;z-index:1
  }
  .visa_container img{
    margin-left:20px
  }
  .coperait_txt{
    user-select:none;color:#999;padding:20px 0
  }
  .footer_txt{
    cursor:pointer;user-select:none;text-decoration: none;margin:0 36px 18px 0;font-family:'Montserrat-Bold';font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:.79px;color:#9f9f9f;color:#999
  }
  .footer_txt:hover{
    color:#fff
  }
  .footer_down::before{
    content:"";display:block;width:80%;height:1px;background:#212729;margin:50px auto
  }
  .footer_desription_section_right{
    padding-top: 40px;
    align-self:flex-start
  }
  .footer_desription_section_right a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left{
    padding-top: 40px;
  }
  .visa_container{
    margin-bottom:60px
  }
  .footer_social_section{
    margin-left:0px
  }
  .footer_logo_section{
    padding-right:0px
  } 

  .iframe {
    width: 460px;
    height: 230px;
  }

  .mobile-iframe {
    width: 300px;
    height: 220px;
    margin-bottom: 10px;
  }
  .mobile-footer_txt{
    cursor:pointer;user-select:none;text-decoration: none;margin:0 5px 10px 0;font-family:'Montserrat-Bold';font-size:11px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:.79px;color:#9f9f9f;color:#999
  }
  .mobile-coperait_txt{
    user-select:none;color:#999;padding:20px 0;font-size: 11px;
  }
@media ( width <= 1100px ) { 
  .iframe {
    width: 320px;
    height: 200px;
  }

  .footer_up {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer_desription_section_right{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
  .footer_desription_section_left{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
}
@media ( width >= 707px ) { 
  .footer_container {
    min-width: 745px;
  }
}

