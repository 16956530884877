.filterItemWrapper {
  display: flex;
  width: 50%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  background-color: #f5faff;
}
.filterItemWrapperMobile {
  display: flex;
  width: 50%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  background-color: #f5faff;
}

.filterItemCol {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
}

.titleItem {
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  cursor: pointer;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  border: none;
  background: linear-gradient(0deg, #ea402f 0%, #ea402f 100%);
  color: #fff;
  overflow: hidden;
  margin: 15px;
}

.titleItem:hover {
    opacity: .7;
}


.titleItem:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@media (width <= 1090px ) {   
  .filterItemCol { 
    height: 60px;
  }
}