.ProductItemWrapper {
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  margin: 10px;
}
.mobileProductItemWrapper {
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 10px;
}
.mobileItemBottomContainer {
  display: flex;
  width: 100%;
  background-color: #f1f1f1;
  align-items: center;
  box-sizing: border-box; 
}
.itemBottomContainer {
  display: flex;
  width: 95%;
  height: 80%;
  background-color: #f1f1f1;
  align-items: center;
  box-sizing: border-box; 
}
.mobileItemTopContainer {
  display: flex;
  width: 100%;
  height: 25px;
  align-items: center; 
  justify-content: center; 
  box-sizing: border-box; 
  background-color: #f92121bf;
}
.itemTopContainer {
  display: flex;
  width: 5%;
  height: 80%;
  align-items: center; 
  justify-content: center; 
  box-sizing: border-box; 
  background-color: #f92121bf;
}

.photoItem {
  display: block;
  width: 50px;
  height: 50px;
}

.mobilePhotoItem {
  display: block;
  width: 30px;
  height: 30px;
}

.mobileCrossItem {
  display: block;
  width: 15px;
  height: 15px;
}

.nameItem {

}

.countItemContainer {
  display: flex;
  width: 50%;
  height: 60%;
  align-items: center;
  justify-content: center;
}

.mobileCountItemContainer {
  display: flex;
  width: 80%;
  height: 60%;
  align-items: center;
  justify-content: center;
}

.decrementCount {
  display: flex;
  width: 15%;
  height: 90%;
  background-color: #ea402f;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.resultCount {
  display: flex;
  width: 20%;
  height: 80%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.incrementCount {
  display: flex;
  width: 15%;
  height: 90%;
  background-color: #ea402f;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.priceItem {

}

.allpriceItem {

}

.productItemPhotoContainer {
  display: flex;
  width: 19%;
  height: 40px;
  padding-left: 3%;
  align-items: center;
  justify-content: flex-start;
}

.mobileProductItemPhotoContainer {
  display: flex;
  width: 11%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.productItemNameContainer {
  display: flex;
  width: 41%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileroductItemNameContainer {
  display: flex;
  width: 33%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.productItemCountContainer {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileProductItemCountContainer {
  display: flex;
  width: 26%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.productItemPriceContainer {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileProductItemPriceContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.productItemAllPriceContainer {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;

}

.mobileProductItemAllPriceContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}