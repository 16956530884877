.topMonumentWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}

.cardMonumentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: white;
}

.mobileCardMonumentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 40px;
  box-sizing: border-box;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-self: center;
  height: 150px;
}
