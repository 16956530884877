.payform-tinkoff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* margin: 2px auto; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 250px;
}
.payform-tinkoff-row {
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  /* flex: 1; */
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #DFE3F3;
  padding: 12px;
  outline: none;
  background-color: #DFE3F3;
  font-size: 15px;
}
.payform-tinkoff-row:focus {
  /* background-color: #FFFFFF; */
  border: 1px solid #616871;
  border-radius: 4px;
}
.payform-tinkoff-btn { 
  display: flex;
  background: linear-gradient(0deg,#ea402f 0%, #ea402f 100%);
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  /* background-color: #FBC520; */
  /* border: 1px solid #FBC520; */
  /* color: #3C2C0B; */
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  font-family: system-ui;
  color: white;
  outline: none;
  cursor: pointer;
  overflow: hidden;  
  user-select: none;
  height: 42px;
}

.payform-tinkoff-btn:last-child {
padding: 10px;
}

.payform-tinkoff-btn:hover {
  /* background-color: #FAB619; */
  opacity: 0.7;
  border: 1px solid #FAB619;
}
 
 