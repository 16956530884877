.viewStoneWrapper {
  display: flex;
  width: 50%;
  height: auto;
  background-color: #f5faff;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.slider_container_all{
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 7px #000;
}

.swiper_container {
  display: flex;
  width: 70%;
  height: auto;
  flex-direction: row;
}

.slider_container_start {
  font-size: 30px;
  color: #333;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.swiper-wrapper {
  height: auto !important;
}

.wrapperArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 92px;
  margin: 0px 10px;
  background-color: rgb(0 0 0 / 50%);
  cursor: pointer;
}

.mobileSlider { 
  width: 80% !important; 
  margin: 0px 0px 30px 0px;
}

.mobileSlider .swiper_container {
  width: 90%; 
}

@media (width <= 1090px ) { 
  .mobileArrow .wrapperArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 55px;
    margin: 0px 10px;
    background-color: rgb(0 0 0 / 50%);
    cursor: pointer;
  }
}

