.App {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
}

#root {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

main {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
}
