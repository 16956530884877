.login_screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filterContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.mobileFilterContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5faff;
}

.titleFilters {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #333;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  background-color: #f5faff;
  }

  .titleMonument {
    font-size: 30px;
    color: #333;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
  }

  .subTitleFilter {
    width: 100%;
    height:120px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #f5faff;
  }

  .mobileSubTitleFilter {
    width: 100%;
    height:360px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5faff;
  }

  .backgroundFilter {
    display: flex;
    background-color: #f5faff;
    justify-content: center;
    align-items: center;
  }

  .answerContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
  }

  .answerBtnWrapper {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
  }

  .answerAbout {
    line-height: 1.5;
    display: flex;
    width: 50%;
    height: auto;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
    color: #676767;
  }

  .popup_textarea{
    width:500px;height:160px;border-radius:20px;border-color:#e7e7e7;resize:none;color:#898989;padding:20px;font-size:1.2em;font-family:'Montserrat-Regular'
  }
  .popup_textarea::placeholder{
    color:#898989;font-size:1em;font-family:'Montserrat-Regular'
  }
  .popup_textarea:focus{
    outline:none;box-shadow:0 0 16px #e7e7e7
  }
  .popup_input:focus{
    outline:none;box-shadow:0 0 16px #e7e7e7
  }
  .popup_input{
    border:1px #e7e7e7 solid;width:540px;height:20px;border-radius:50px;border-color:#e7e7e7;resize:none;color:#898989;padding:15px;font-size:1.2em;font-family:'Montserrat-Regular';margin-bottom: 30px;
  }
  .popup_input:focus{
    border:1px #e7e7e7 solid;width:540px;height:20px;border-radius:50px;border-color:#e7e7e7;resize:none;color:#898989;padding:15px;font-size:1.2em;font-family:'Montserrat-Regular'
  }

  .formWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
  }

  .mobileItem {
    width: 270px !important;
  }

  @media (width <= 1090px ) { 
    .mobileTitle .titleText {
      font-size: 30px;
    } 
  }


