.aboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 50px;
  background-color: white;
}

.mobileAboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  background-color: white;
}

.mobileAboutWrapper .aboutTextWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  background-color: white;
}

.aboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.mobileAboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.aboutBottomWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.mobileAboutBottomWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.aboutTextWrapper {
  font-size: 17px;
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  font-family: sans-serif;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
}

.aboutPhotoWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileAboutWrapper .aboutPhotoWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.photoAbout {
  width: 50%;
}