 .shopBlockWrapper {
   display: flex;
   width: 100%;
   height: 100%;
   align-items: center;
   justify-content: center;
   background-color: white;
 }

 .mobileShopBlockWrapper {
  display: flex;
  flex-direction: column;
   width: 100%;
   height: 100%;
   align-items: center;
   justify-content: center;
   background-color: white;
 }

 .cross {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 230px;
  right: 5%;
  cursor: pointer;
 }

 .imageShopBlockWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
 }

 .imageShopBlock {
  display: flex;
  width: 50%;
  height: auto;
  align-items: center;
  justify-content: center;
 }

 .infoShopBlockWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }

 .titleShopBlock {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  color: #000000;
  font-weight: 600;
  font-family: Cormorant;
  font-size: 26px;
  line-height: 1.35;
 }

 .telephoneShopBlockMobile {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  color: #000000;
  font-weight: 600;
  font-family: Cormorant;
  font-size: 8px;
  line-height: 1.35;
 }

 .priceShopBlock {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.35;
    font-family: 'Roboto',Arial,sans-serif;
 }

 .aboutShopBlock {
  display: flex;
  width: 100%;
  height: auto;
  align-items:  flex-start;
  justify-content: flex-start;
  font-family: Roboto;
  font-size: 17px;
  line-height: 1.55;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
 }

 .infoblockShopBlock {
  display: flex;
  width: 100%;
  height: auto;
  align-items:  flex-start;
  justify-content: flex-start;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.55; 
  color: #000;
  margin-bottom: 20px;
  flex-direction: column;
 }

 .telephoneShopBlock {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 700;
  color: #000;
 }

 .btnShopBlockWrapper {
  display: flex;
   width: 100%;
   height: 80px;
       align-items: center;
   justify-content: flex-start;
 }

 strong {
  font-weight: 700;
}